export const config = {
    type: 'main',
    local_port: "3000",
    node_ca_url: "http://evote52-atom.dltc.spbu.ru:3000",
    java_api_url: "https://client.evote52.dltc.spbu.ru:443/api",
    ws_connect: "wss://client.evote52.dltc.spbu.ru/ws",
    node_ca_get_auth_url_path: "/esia/get_auth_url_client",
    login_redirect: "/esia/login_redirect",
    java_api_esia_validate_path: "/esia/validate",
    users_reset: "/users/reset",
    enable_esia: false,
    lang: "ru",
    ws_user: "client",
    ws_pass: "client",
    admin_url: "https://admin.evote52.dltc.spbu.ru",
    ca_url: "https://client.evote52.dltc.spbu.ru/hlf/api/v1",
    max_input_length: 100,
    options_number_to_select_all: 4,
    enable_phone: false,
    default_offset: 3,
        "system_type": "political"
    };
